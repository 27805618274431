body {
  overflow: hidden;
}


.image-with-highlighted-area__hovered-area {
  z-index: 1000000;
  pointer-events: none;
}

.zoom-in {
  cursor: zoom-in !important;
}

.zoom-out {
  cursor: zoom-out !important;
}

.dimmer {
  background-color: rgba(0, 0, 0, .7);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 1rem;
}

.fade-in {
  animation: fadeIn ease .2s;
  -webkit-animation: fadeIn ease .2s;
  -moz-animation: fadeIn ease .2s;
  -o-animation: fadeIn ease .2s;
  -ms-animation: fadeIn ease .2s;
}

.fade-in-on-hover {
  opacity: 0.6;
}

.fade-in-on-hover:hover {
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.floating-filter-wrapper {
  position: fixed;
  bottom: 4rem;
  left: 4rem;
  z-index: 100;
}

.texts-wrapper {
  overflow-y: scroll;
  height: 100%;
  padding-right: 4rem;
  padding-top: 2rem;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-95vh {
  height: 95vh;
}

.filter-icon {
  width: 2rem;
}

.metadata .badge {
  margin: 0 8px 8px 0;
}

.fullscreen-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullscreen-wrapper h1 {
  font-weight: 300;
}

.loading-text {
  background-image:
    linear-gradient(to right, #343434, #565656, #747474, #989898, #bcbcbc, #dcdcdc, #bcbcbc, #989898, #747474, #343434);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow-animation 400s linear infinite;
}

@keyframes rainbow-animation {
  to {
    background-position: 4500vh;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* SPINER */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 1px solid #32325d;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}